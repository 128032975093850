import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class ServiceV5 extends Component {

    render() {

		let services = [
			{
				title: "Full-service vacation rental oversight",
				descript: "",
				img: "flaticon-home-1"
			},
			{
				title: "Proactive and expert property maintenance",
				descript: "",
				img: "flaticon-user"
			},
			{
				title: "Round-the-clock emergency support for properties",
				descript: "",
				img: "flaticon-hospital"
			},
			{
				title: "Maintenance and replacement of damaged furniture",
				descript: "",
				img: "flaticon-armchair"
			},
			{
				title: "Professional cleaning services & detailed turnover management",
				descript: "",
				img: "flaticon-add-to-cart"
			},
			{
				title: "Immediate repair and service request handling",
				descript: "",
				img: "flaticon-square-shape-design-interface-tool-symbol"
			}
		];

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__service-area section-bg-1 pt-115 pb-70 go-top">
				<div className="container">
				<div className="row">
					<div className="col-lg-12">
					<div className="section-title-area ltn__section-title-2--- text-center">
						<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Our Services</h6>
						<h1 className="section-title">Our Core Services</h1>
					</div>
					</div>
				</div>
				<div className="row  justify-content-center">
					{services.map((service, index) => (
					<div className="col-lg-4 col-sm-6 col-12" key={index}>
						<div className="ltn__feature-item ltn__feature-item-3 text-center bg-white  box-shadow-1">
							<div className="ltn__feature-icon">
								<span><i className={service.img} /></span>
							</div>
							<div className="ltn__feature-info">
							{/* <h3><Link to="/service-details">Property Management</Link></h3>
							<h3>Property Management</h3>
							<p>Experience the ultimate in luxury and comfort at our prime locations with world-class amenities and tastefully designed interiors. Our exceptional service ensures a smooth and unforgettable stay, whether you're here for a weekend getaway or a family vacation. Come, indulge in our hospitality</p> */}
							<h3>{service.title}</h3>
							<p>{service.descript}</p>
							</div>
						</div>
					</div>
					))}
					{/* <div className="col-lg-4 col-sm-6 col-12">
					<div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
						<div className="ltn__feature-icon">
						<span><i className="flaticon-mortgage" /></span>
						</div>
						<div className="ltn__feature-info">
						<h3><Link to="/service-details">Mortgage Service</Link></h3>
						<p>over 1 million+ homes for sale available on the website, we can match you with a house you will want to call home.</p>
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-sm-6 col-12">
					<div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
						<div className="ltn__feature-icon">
						<span><i className="flaticon-operator" /></span>
						</div>
						<div className="ltn__feature-info">
						<h3><Link to="/service-details">Consulting Service</Link></h3>
						<p>over 1 million+ homes for sale available on the website, we can match you with a house you will want to call home.</p>
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-sm-6 col-12">
					<div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
						<div className="ltn__feature-icon">
						<span><i className="flaticon-house-1" /></span>
						</div>
						<div className="ltn__feature-info">
						<h3><Link to="/service-details">Home Buying</Link></h3>
						<p>over 1 million+ homes for sale available on the website, we can match you with a house you will want to call home.</p>
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-sm-6 col-12">
					<div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
						<div className="ltn__feature-icon">
						<span><i className="flaticon-house-3" /></span>
						</div>
						<div className="ltn__feature-info">
						<h3><Link to="/service-details">Home Selling</Link></h3>
						<p>over 1 million+ homes for sale available on the website, we can match you with a house you will want to call home.</p>
						</div>
					</div>
					</div>
					<div className="col-lg-4 col-sm-6 col-12">
					<div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
						<div className="ltn__feature-icon">
						<span><i className="flaticon-official-documents" /></span>
						</div>
						<div className="ltn__feature-info">
						<h3><Link to="/service-details">Escrow Services</Link></h3>
						<p>over 1 million+ homes for sale available on the website, we can match you with a house you will want to call home.</p>
						</div>
					</div>
					</div> */}
				</div>
			</div>
			</div>
        }
}

export default ServiceV5